import React from "react";
import "../new/new.css";
import Formulario from "../components/Formulario";

export const NewUser = () => {
  return (
    <>
      <Formulario />
    </>
  );
};
