import React from "react";
import { Link } from "react-router-dom";
import './show.css'
import Doc from 'assets/img/Documento.png'
import LOGO from 'assets/svg/LOGO.svg'
import DocMini from 'assets/img/Documentomini.png'
import FavoriteDoc from "views/admin/uploadview/components/FavoriteDoc";

export const ShowOrder = () => {
  return (
    <div className="flex w-full flex-col gap-5 pt-2">
      <div className="mb-2">ShowOrder</div>
      <ul>
        <li>
          <Link to={-1} >
            Back
          </Link>
        </li>
      </ul>
      <div className="side-bar">
      <div className="sidebar-notification">
        <div className="frame">
          <div className="separator" />
          <img className="LOGO" alt="Logo" src={LOGO} />
        </div>
        <div className="div">
          <div className="separator-2" />
          <div className="fi-bs-check-wrapper">
            {/*<FiBsCheck className="fi-bs-check" color="#32D74B" />*/}
          </div>
          <div className="div-wrapper">
            <div className="text-wrapper">Example doc. pdf</div>
          </div>
        </div>
        <img className="vista-doc-mini-list" alt="Vista doc mini list" src={DocMini} />
      </div>
    </div>
      <div className="frame">
      <div className="large-dropzone">
        <img className="vista-doc" alt="Vista doc" src={Doc} />
        <p className="text-wrapper">Vista previa - 1 / 1</p>
        <div className="fi-bs-angle-left-wrapper">
          {/*<FiBsAngleLeft className="icon-instance-node" color="#0F81FE" />*/}
        </div>
        <div className="fi-bs-angle-right-wrapper">
          {/*<FiBsAngleRight className="icon-instance-node" color="#0F81FE" />*/}
        </div>
      </div>
      <div className="div">
        <div className="text-wrapper-2">Imprimir</div>
        <div className="text-wrapper-3">1 Documento a imprimir</div>
        <div className="div-2">
          <div className="overlap-group">
            {/*<FiRrAngleSmallDown className="fi-rr-angle-small" color="#202020" />*/}
            {/*<FiRrPrint className="fi-rr-print" color="#202020" />*/}
            <p className="p">Capital Work / Hp Laser 1100</p>
          </div>
          <div className="text-wrapper-4">Destino</div>
        </div>
        <div className="div-3">
          <div className="overlap-group">
            {/*<FiRrAngleSmallDown className="fi-rr-angle-small" color="#202020" />*/}
            <div className="text-wrapper-5">1</div>
          </div>
          <div className="text-wrapper-4">Páginas por hoja</div>
        </div>
        <div className="div-4">
          <div className="overlap-group">
            {/*<FiRrAngleSmallDown className="fi-rr-angle-small" color="#202020" />*/}
            <div className="text-wrapper-5">Original</div>
          </div>
          <div className="text-wrapper-4">Escala</div>
          <div className="text-wrapper-6">Añadir a favorito</div>
        </div>
        <div className="div-5">
          <div className="overlap-group">
            {/*<FiRrAngleSmallDown className="fi-rr-angle-small" color="#202020" />*/}
            <div className="text-wrapper-5">Blanco y negro</div>
          </div>
          <div className="text-wrapper-4">Color</div>
        </div>
        <div className="div-6">
          <div className="text-wrapper-4">Tamaño del papel</div>
          <div className="group">
            <div className="overlap-group-2">
              <img className="vector" alt="Vector" src="vector-15.svg" />
              <div className="text-wrapper-7">A4</div>
              <div className="text-wrapper-8">Carta</div>
              <div className="text-wrapper-9">Oficio</div>
              <div className="text-wrapper-10">A3</div>
              {/*<IconComponentNode className="fi-rr-angle-small" />*/}
            </div>
          </div>
        </div>
        <div className="div-7">
          <div className="text-wrapper-4">Páginas</div>
          <div className="overlap-group-wrapper">
            <div className="overlap-group-3">
              {/*<FiRrAngleSmallDown className="fi-rr-angle-small" color="#202020" />*/}
              <div className="text-wrapper-11">Personalizado</div>
            </div>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap-group-3">
              <div className="text-wrapper-12">1</div>
            </div>
          </div>
        </div>
        <div className="boton">
          <div className="overlap">
            <div className="rectangle" />
            <div className="text-wrapper-13">Imprimir</div>
          </div>
        </div>
        <div className="div-wrapper">
          <div className="overlap">
            <div className="rectangle-2" />
            <div className="text-wrapper-14">Cancelar</div>
          </div>
        </div>
        <div className="div-wrapper">
          <div className="overlap">
            <div className="rectangle-2" />
            <div className="text-wrapper-14">Cancelar</div>
          </div>
        </div>
        {/*<FiSrCheckbox className="fi-sr-checkbox" color="#32D74B" />*/}
      </div>
    </div>
    <FavoriteDoc/>
    </div>
  );
};
