import { Dot } from "assets/svg/dot";

export const PrinterItem = (props) => {
  const set = (e) => {
    e.preventDefault();
    props.onPress();
  };

  return (
    <a onClick={set} className="cursor-pointer">
      <div className=" relative m-4 mt-1  rounded-2xl bg-white p-3 pt-2 shadow">
        <button
          className={`absolute right-5 top-3 h-5  rounded-lg text-center text-xs font-bold text-white`}
        >
          <Dot color={props.selected ? "#32D74B" : "#FF453A"} />
        </button>
        <h1 className="text-neutral-800 w-96 text-lg font-bold leading-7">
          {props.name}
        </h1>
        <p className=" text-zinc-500 text-base font-normal leading-relaxed">
          Dirección: {props.address}
        </p>
        <p className=" text-zinc-500 text-base font-normal leading-relaxed">
          Color: {props.type}
        </p>
        <p className=" text-zinc-500 text-base font-normal leading-relaxed">
          Tipo de hojas: {props.paper_width}
        </p>
      </div>
    </a>
  );
};
