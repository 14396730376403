import { PrinterItem } from "components/PrinterItem";
import { useForm } from "hooks/useForm";
import React from "react";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "store/StoreProvider";
import { getDivisionsByHeadquarter } from "store/sagas/Divisions";
import { newEmploye } from "store/sagas/Employes";
import { getAllHeadquarter } from "store/sagas/Headquarter";
import { getPrintersByIds } from "store/sagas/Print";
import Swal from "sweetalert2";

function Formulario() {
   const [store] = React.useContext(StoreContext);
   const [headquarters, setHeadquaerts] = React.useState([]);
   const [divisions, setDivisions] = React.useState([]);
   const [printers, setPrinters] = React.useState([]);
   const [selectedPrinters, setSelectedPrinters] = React.useState([]);

   React.useEffect(() => {
     getAllHeadquarter({ id: store.user.id }).then((res) => {
       if (!!res) {
         setHeadquaerts(res.Sedes);
       }
     });
   }, []);

   const form = {
     name: "",
     address: "",
     email: "",
     phone: "",
     nPaper: "",
     headquarter: "",
     division: "",
     user_type:"",
   };
   const [values, handleChange] = useForm(form);

   const navigate = useNavigate();

   const handleSubmit = (e) => {
     e.preventDefault();
     let obj = {
       name: values.name,
       email: values.email,
       address: values.address,
       phone: values.phone,
       nPaper: values.nPaper,
       divisionID: values.division,
       headquarterID: values.headquarter,
       printers: selectedPrinters,
       b2bid: store.user.b2bid,
       user_type: Number(values.user_type),
     };

     newEmploye(obj).then((res) => {
       Swal.fire({
         title: "Todo listo",
         text: "Has creado un nuevo usuario, no olvides informarle que ya puede iniciar sesion en PrintGo",
         icon: "success",
         showDenyButton: true,
         showCancelButton: false,
         confirmButtonText: "Crear otro",
         denyButtonText: `Ver Usuario`,
       }).then((result) => {
         /* Read more about isConfirmed, isDenied below */
         if (result.isConfirmed) {
           handleChange({ name: "", value: "" }, form);
           setSelectedPrinters([]);
           setPrinters([]);
         } else if (result.isDenied) {
           navigate(`/admin/users/show/${res._id}`);
         }
       });
     });
   };

   const handleHeadquarter = (e) => {
     handleChange({ name: "headquarter", value: e.target.value });     
     getDivisionsByHeadquarter({ _id: e.target.value }).then((res) => {
       setDivisions(res.division);
     });
   };

   const handleDivision = (e) => {
     handleChange({ name: "division", value: e.target.value });
     let division = divisions.find((item) => item._id === e.target.value);
     getPrintersByIds({ printers: division.printers }).then((res) => {
       setPrinters(res);
       if (res.length > 0) {
         let selectedIds = res.map(({ _id }) => _id);
         setSelectedPrinters(selectedIds);
       }
     });
   };

   const printerToggle = (id) => {
     if (selectedPrinters.includes(id)) {
       let filtered = selectedPrinters.filter((item) => item !== id);
       setSelectedPrinters(filtered);
     } else {
       setSelectedPrinters([id, ...selectedPrinters]);
     }
   };
  return (
    <form onSubmit={handleSubmit}>
      <div className="m-4 grid grid-cols-2 gap-1 ">
        <div className="m-2 rounded-lg bg-white p-6 shadow-md">
          <div className="mp-4 mb-4 flex flex-row">
            <h1 className=" mr-5 font-bold text-gray-700">Nombre:</h1>
            <input
              required="required"
              type="text"
              id="name"
              name="name"
              onChange={(e) =>
                handleChange({ name: "name", value: e.target.value })
              }
              value={values.name}
              className="w-full rounded border bg-gray-100 px-3 py-2 focus:border-blue-500 focus:outline-none"
            />
          </div>

          <div className="mp-5 mb-4 flex flex-row">
            <label
              htmlFor="direccion"
              className="mr-2 block font-bold text-gray-700"
            >
              Dirección:
            </label>
            <input
              required="required"
              type="text"
              id="address"
              name="address"
              onChange={(e) =>
                handleChange({ name: "address", value: e.target.value })
              }
              value={values.address}
              className=" w-full rounded border bg-gray-100 px-3  py-2 focus:border-blue-500 focus:outline-none"
            />
          </div>

          <div className="mp-4 mb-4 flex flex-row">
            <label
              htmlFor="email"
              className="mr-9 block font-bold text-gray-700"
            >
              Email:
            </label>
            <input
              required="required"
              type="email"
              id="email"
              onChange={(e) =>
                handleChange({ name: "email", value: e.target.value })
              }
              value={values.email}
              className="w-full rounded border bg-gray-100 px-3  py-2  focus:border-blue-500 focus:outline-none"
            />
          </div>

          <div className="mp-4 mb-4 flex flex-row">
            <label
              htmlFor="telefono"
              className="mr-3 block font-bold text-gray-700"
            >
              Teléfono:
            </label>
            <input
              required="required"
              type="tel"
              id="phone"
              name="phone"
              onChange={(e) =>
                handleChange({ name: "phone", value: e.target.value })
              }
              value={values.phone}
              className="w-full rounded border bg-gray-100 px-3 py-2  focus:border-blue-500 focus:outline-none"
            />
          </div>

          <div className="mp-4 mb-4 flex flex-row">
            <label
              htmlFor="cantidadPapel"
              className="mb-2 block font-bold text-gray-700"
            >
              Cantidad de Papel:
            </label>
            <input
              type="number"
              id="amount_paper"
              name="amount_paper"
              onChange={(e) =>
                handleChange({ name: "nPaper", value: e.target.value })
              }
              value={values.nPaper}
              className="w-full rounded border bg-gray-100 px-3  py-2 focus:border-blue-500 focus:outline-none"
            />
          </div>
          <div className="mp-4 mb-4 flex flex-row">
            <label
              htmlFor="cantidadPapel"
              className="mb-2 block font-bold text-gray-700"
            >
              Tipo de empleado:
            </label>
            <select
              required="required"
              type="selet"
              id="user_type"
              name="user_type"
              onChange={(e) =>
                handleChange({ name: "user_type", value: e.target.value })
              }
              className="w-full rounded border bg-gray-100  px-3   py-2 focus:border-blue-500 focus:outline-none"
            >
              <option value="3">Empleado</option>
              <option value="2">Adminstrador</option>
            </select>
          </div>
        </div>
        <div className="m-2 rounded-lg bg-white p-6 shadow-md ">
          <div className="form-group flex flex-row">
            <label
              htmlFor="cantidadPapel"
              className="mr-2 block font-bold text-gray-700"
            >
              Sede:
            </label>
            <select
              required="required"
              type="selet"
              id="print"
              name="print"
              onChange={handleHeadquarter}
              value={values.headquarter}
              className="w-full rounded border bg-gray-100  px-3   py-2 focus:border-blue-500 focus:outline-none"
            >
              <option value="" disabled selected>
                Selecciona una sede
              </option>
              {headquarters.map((item, key) => (
                <option value={item._id} key={key}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          <div className="form-group mt-5 flex flex-row">
            <label
              htmlFor="cantidadPapel"
              className="mr-2 block font-bold text-gray-700"
            >
              División:
            </label>
            <select
              required="required"
              type="selet"
              id="print"
              name="print"
              onChange={handleDivision}
              value={values.division}
              className="w-full rounded border bg-gray-100  px-3   py-2 focus:border-blue-500 focus:outline-none"
            >
              <option value="" disabled selected>
                Selecciona una división
              </option>
              {divisions.map((item, key) => (
                <option value={item._id} key={key}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          <div className=" mt-5 h-[50vh] w-auto rounded-xl  bg-gray-100 p-4  ">
            <label className="mb-4">Impresoras:</label>
            <div className="printers-container mt-5 h-[40vh] overflow-y-auto">
              {printers.map((item, key) => (
                <PrinterItem
                  key={key}
                  {...item}
                  onPress={() => printerToggle(item._id)}
                  selected={selectedPrinters.includes(item._id)}
                />
              ))}
            </div>
          </div>

          <div
            className="
          mt-20
          flex justify-end "
          >
            <button
              type="reset"
              className="  focus:shadow-outline mr-4 rounded bg-gray-400 px-4 py-2 font-bold text-white hover:bg-red-700 focus:outline-none"
            >
              Cancelar
            </button>

            <button
              type="submit"
              className=" focus:shadow-outline ml-4 rounded bg-blue-400  px-4 py-2 font-bold text-white hover:bg-blue-500  focus:outline-none"
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default Formulario;
