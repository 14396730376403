const FavoriteDoc = () => {
  return (
    <div className="inline-flex items-start justify-start gap-5">
      <div className="relative w-full min-h-[50vh]">
        <div className="absolute left-[28px] top-[80px] h-[444px] w-[1538px]">
          <div className="absolute left-[312px] top-0 inline-flex h-[444px] w-[289px] flex-col items-start justify-start rounded-[15px] bg-indigo-50 shadow">
            <div className="relative h-[306px] w-[264px]">
              <div className="bg-stone-300 absolute left-0 top-0 h-[306px] w-[264px] rounded-lg" />
              <div className="absolute left-[-3px] top-[-5px] h-80 w-[271px] bg-white" />
              <div className="absolute left-[232px] top-[11px] h-6 w-6 bg-yellow-400 bg-opacity-0" />
            </div>
            <div className="relative h-[33.32px] w-[113px] opacity-10">
              <div className="bg-neutral-800 border-neutral-800 absolute left-0 top-0 h-[33.32px] w-[113px] rounded-[10px] border" />
              <div className="absolute left-0 top-[0.32px] h-[33px] w-[113px] text-center text-sm font-medium leading-tight text-white">
                Vista Previa
              </div>
            </div>
            <div className="text-slate-400 w-[6.36px] text-sm font-medium leading-tight">
              •
            </div>
            <div className="relative h-[49px] w-[264px] opacity-10">
              <div className="absolute left-0 top-[1px] h-12 w-[264px] rounded-[10px] bg-indigo-600" />
              <div className="absolute left-0 top-0 h-[49px] w-[264px] text-center text-sm font-bold leading-tight text-white">
                Imprimir
              </div>
            </div>
          </div>
          <div className="absolute left-[625px] top-0 inline-flex h-[444px] w-72 flex-col items-start justify-start rounded-[15px] bg-indigo-50 shadow">
            <div className="relative h-[306px] w-[264px]">
              <div className="bg-stone-300 absolute left-0 top-0 h-[306px] w-[264px] rounded-lg" />
              <div className="absolute left-[-3px] top-[-5px] h-80 w-[271px] bg-white" />
              <div className="absolute left-[232px] top-[11px] h-6 w-6 bg-yellow-400 bg-opacity-0" />
            </div>
            <div className="relative h-[33.32px] w-[113px] opacity-10">
              <div className="bg-neutral-800 border-neutral-800 absolute left-0 top-0 h-[33.32px] w-[113px] rounded-[10px] border" />
              <div className="absolute left-0 top-[0.32px] h-[33px] w-[113px] text-center text-sm font-medium leading-tight text-white">
                Vista Previa
              </div>
            </div>
            <div className="text-slate-400 w-[6.36px] text-sm font-medium leading-tight">
              •
            </div>
            <div className="relative h-[49px] w-[264px] opacity-10">
              <div className="absolute left-0 top-[1px] h-12 w-[264px] rounded-[10px] bg-indigo-600" />
              <div className="absolute left-0 top-0 h-[49px] w-[264px] text-center text-sm font-bold leading-tight text-white">
                Imprimir
              </div>
            </div>
          </div>
          <div className="absolute left-[937px] top-0 inline-flex h-[444px] w-[289px] flex-col items-start justify-start rounded-[15px] bg-indigo-50 shadow">
            <div className="relative h-[306px] w-[264px]">
              <div className="bg-stone-300 absolute left-0 top-0 h-[306px] w-[264px] rounded-lg" />
              <div className="absolute left-[-3px] top-[-5px] h-80 w-[271px] bg-white" />
              <div className="absolute left-[232px] top-[11px] h-6 w-6 bg-yellow-400 bg-opacity-0" />
            </div>
            <div className="relative h-[33.32px] w-[113px] opacity-10">
              <div className="bg-neutral-800 border-neutral-800 absolute left-0 top-0 h-[33.32px] w-[113px] rounded-[10px] border" />
              <div className="absolute left-[2px] top-[0.32px] h-[33px] w-[111px] text-center text-sm font-medium leading-tight text-white">
                Vista Previa
              </div>
            </div>
            <div className="relative h-[49px] w-[264px] opacity-10">
              <div className="absolute left-0 top-[1px] h-12 w-[264px] rounded-[10px] bg-indigo-600" />
              <div className="absolute left-0 top-0 h-[49px] w-[264px] text-center text-sm font-bold leading-tight text-white">
                Imprimir
              </div>
            </div>
          </div>
          <div className="absolute left-[1249px] top-0 inline-flex h-[444px] w-[289px] flex-col items-start justify-start rounded-[15px] bg-indigo-50 shadow">
            <div className="relative h-[306px] w-[264px]">
              <div className="bg-stone-300 absolute left-0 top-0 h-[306px] w-[264px] rounded-lg" />
              <div className="absolute left-[-4px] top-[-5px] h-80 w-[271px] bg-white" />
              <div className="absolute left-[232px] top-[11px] h-6 w-6 bg-yellow-400 bg-opacity-0" />
            </div>
            <div className="relative h-[33.32px] w-[113px] opacity-10">
              <div className="bg-neutral-800 border-neutral-800 absolute left-0 top-0 h-[33.32px] w-[113px] rounded-[10px] border" />
              <div className="absolute left-0 top-[0.32px] h-[33px] w-[113px] text-center text-sm font-medium leading-tight text-white">
                Vista Previa
              </div>
            </div>
            <div className="relative h-[49px] w-[264px] opacity-10">
              <div className="absolute left-0 top-[1px] h-12 w-[264px] rounded-[10px] bg-indigo-600" />
              <div className="absolute left-0 top-0 h-[49px] w-[264px] text-center text-sm font-bold leading-tight text-white">
                Imprimir
              </div>
            </div>
          </div>
          <div className="absolute left-0 top-0 inline-flex h-[444px] w-[289px] flex-col items-start justify-start rounded-[15px] bg-indigo-50 shadow">
            <div className="relative h-[306px] w-[264px]">
              <div className="bg-stone-300 absolute left-0 top-0 h-[306px] w-[264px] rounded-lg" />
              <img
                className="absolute left-[-3px] top-[-5px] h-80 w-[271px]"
                src="https://via.placeholder.com/271x320"
              />
              <div className="absolute left-[232px] top-[11px] h-6 w-6 bg-yellow-400 bg-opacity-0" />
            </div>
            <div className="relative h-[33.32px] w-[113px]">
              <div className="bg-neutral-800 border-neutral-800 absolute left-0 top-0 h-[33.32px] w-[113px] rounded-[10px] border" />
              <div className="absolute left-0 top-[0.32px] h-[33px] w-[113px] text-center text-sm font-medium leading-tight text-white">
                Vista Previa
              </div>
            </div>
            <div className="relative h-[39px] w-[264px]">
              <div className="text-slate-400 absolute left-[75.28px] top-[19px] w-[6.36px] text-sm font-medium leading-tight">
                •
              </div>
              <div className="absolute left-0 top-0 w-[264px] text-center text-base font-medium leading-none text-indigo-900">
                Example doc .pdf
              </div>
            </div>
            <div className="relative h-[49px] w-[264px]">
              <div className="absolute left-0 top-[1px] h-12 w-[264px] rounded-[10px] bg-indigo-600" />
              <div className="absolute left-0 top-0 h-[49px] w-[264px] text-center text-sm font-bold leading-tight text-white">
                Imprimir
              </div>
            </div>
          </div>
        </div>
        <div className="absolute left-[319px] top-[52px] h-6 w-6 origin-top-left -rotate-180" />
      </div>
    </div>
  );
};

export default FavoriteDoc;
