import React from "react";
import { MdModeEditOutline } from "react-icons/md";
import image1 from "assets/img/profile/image1.png";
import image2 from "assets/img/profile/image2.png";
import image3 from "assets/img/profile/image3.png";
import Card from "components/card";
import { useForm } from "hooks/useForm";
import { updateProfile } from "store/sagas/Employes";
import Swal from "sweetalert2";
import { StoreContext } from "store/StoreProvider";
import { deleteEmployes } from "store/sagas/Employes";
import { useNavigate } from "react-router-dom";



const Project = (props) => {

  const [update, setUpdate] = React.useState(false)
  const [store] = React.useContext(StoreContext)
  
  let form = {
    name: props?.name || "",
    phone: props?.phone || "",
    email: props?.email || "",
    nPaper: props?.nPaper || "",
    user_type: props?.user_type || "",
  };
  const [values, handleChange] = useForm(form)

  React.useEffect(() => {
    if (!!props.name) {
      handleChange(
        { name: "", value: "" },
        {
          name: props?.name,
          phone: props?.phone,
          email: props?.email,
          nPaper: props?.nPaper,
          user_type: props?.user_type,
        }
      );      
    }
  }, [props.name]);


  const handleUpd = (obj) => {
    if (!update) {
      setUpdate(true)
    }
    handleChange(obj);
  }


  const handleSubmit = () => {
    let obj = {
      _id: props._id,
      name: values.name,
      phone: values.phone,
      email: values.email,
      nPaper: values.nPaper,
      user_type:Number(values.user_type),
    };    
    updateProfile(obj).then(() => {
      Swal.fire({
        title: "Muy bien",
        text: "Usuario actualizado",
        icon: "success",        
        confirmButtonText: "Ok"
      }).then((result) => {
        setUpdate(false)
        props.getUser()
      });
    })
  }


   const handleStatus = () => {
     let obj = {
       _id: props._id,
      status: !props.status
     };
     updateProfile(obj).then(() => {
       Swal.fire({
         title: "Muy bien",
         text: "Usuario actualizado",
         icon: "success",
         confirmButtonText: "Ok",
       }).then((result) => {
         setUpdate(false);
         props.getUser();
       });
     });
   };


   const navigate = useNavigate();

   const handleDelte = () => {
     Swal.fire({
       title: "Eliminar",
       text: "¿Quieres eliminar este usuario?",
       icon: "warning",
       showCancelButton: true,
       confirmButtonColor: "#3085d6",
       cancelButtonColor: "#d33",
       confirmButtonText: "ACEPTAR",
       cancelButtonText: "CANCELAR",
     }).then((result) => {
       if (result.isConfirmed) {
         let obj = {
           _id: props._id,
         };
         deleteEmployes(obj).then(() => {
           Swal.fire("¡Elminado!", "", "success");
           navigate(`/admin/users/`);
         });
       }
     });
   };

   





  return (
    <Card extra={"w-full p-4 h-full"}>
      <div className="mb-8 w-full">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Datos de perfil
        </h4>
      </div>
      <form className="w-full ">
        <div className="mb-6 md:flex md:items-center">
          <div className="md:w-1/3">
            <label
              className="mb-1 block pr-4 font-bold text-gray-500 md:mb-0 md:text-left"
              htmlFor="inline-full-name"
            >
              Nombre
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              defaultValue={props?.name}
              className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight text-gray-700 focus:border-purple-500 focus:bg-white focus:outline-none"
              id="inline-full-name"
              onChange={(e) =>
                handleUpd({ name: "name", value: e.target.value })
              }
              value={!update ? props?.name : values.name}
            />
          </div>
        </div>
        <div className="mb-6 md:flex md:items-center">
          <div className="md:w-1/3">
            <label
              className="mb-1 block pr-4 font-bold text-gray-500 md:mb-0 md:text-left"
              htmlFor="inline-email"
            >
              Email
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              defaultValue={props?.phone}
              className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight text-gray-700 focus:border-purple-500 focus:bg-white focus:outline-none"
              id="inline-email"
              type="email"
              onChange={(e) =>
                handleUpd({ name: "email", value: e.target.value })
              }
              value={!update ? props?.email : values.email}
            />
          </div>
        </div>
        <div className="mb-6 md:flex md:items-center">
          <div className="md:w-1/3">
            <label
              className="mb-1 block pr-4 font-bold text-gray-500 md:mb-0 md:text-left"
              htmlFor="inline-phone"
            >
              Telefono
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              defaultValue={props?.phone}
              className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight text-gray-700 focus:border-purple-500 focus:bg-white focus:outline-none"
              id="inline-phone"
              type="number"
              onChange={(e) =>
                handleUpd({ name: "phone", value: e.target.value })
              }
              value={!update ? props?.phone : values.phone}
            />
          </div>
        </div>
        <div className="mb-6 md:flex md:items-center">
          <div className="md:w-1/3">
            <label
              className="mb-1 block pr-4 font-bold text-gray-500 md:mb-0 md:text-left"
              htmlFor="inline-phone"
            >
              Papel Asignado
            </label>
          </div>
          <div className="md:w-2/3">
            <input
              defaultValue={props?.nPaper}
              className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight text-gray-700 focus:border-purple-500 focus:bg-white focus:outline-none"
              id="inline-phone"
              type="number"
              onChange={(e) =>
                handleUpd({ name: "nPaper", value: e.target.value })
              }
              value={!update ? props?.nPaper : values.nPaper}
            />
          </div>
        </div>
        <div className="mb-6 md:flex md:items-center">
          <div className="md:w-1/3">
            <label
              className="mb-1 block pr-4 font-bold text-gray-500 md:mb-0 md:text-left"
              htmlFor="inline-phone"
            >
              Tipo de Empleado
            </label>
          </div>
          <div className="md:w-2/3">
            <select
              name="user_type"
              id="user_type"
              defaultValue={props?.user_type}
              value={!update ? props?.user_type : values.user_type}
              onChange={(e) =>
                handleUpd({ name: "user_type", value: e.target.value })
              }
              className="w-full rounded border bg-gray-100  px-3   py-2 focus:border-blue-500 focus:outline-none"
            >
              <option value="1">Empleado</option>
              <option value="2">Administrador</option>
            </select>
          </div>
        </div>
      </form>

      <div className="rtl text-neutral-800 h w-full justify-items-end  text-end text-sm font-bold leading-tight text-opacity-20">
        <button
        onClick={handleDelte}
          className="focus:shadow-outline mr-2 rounded bg-red-500 px-4 py-2 font-bold text-white shadow hover:bg-red-300 focus:outline-none"
          type="button"
        >
          Eliminar
        </button>

        <button
          className={`focus:shadow-outline mr-2 rounded   px-4 py-2 font-bold text-white  shadow    focus:outline-none ${
            props?.status
              ? "bg-orange-500  hover:bg-orange-300 "
              : "bg-green-500 hover:bg-green-300"
          }`}
          type="button"
          onClick={handleStatus}
        >
          {props?.status ? "Inhabilitar" : "Habilitar"}
        </button>

        <button
          className={`focus:shadow-outline rounded px-4 py-2 font-bold text-white shadow focus:outline-none ${
            !update
              ? "bg-gray-400 hover:bg-gray-500"
              : "bg-blue-500 hover:bg-blue-400"
          }`}
          disabled={!update}
          onClick={handleSubmit}
          type="button"
        >
          Guardar
        </button>
      </div>
    </Card>
  );
};

export default Project;
