import React from "react";
import { Link } from "react-router-dom";
import Formulario from "./components/Formulario";
import { Divisions } from "..";

export const NewDivision = () => {
  return (
    <>
      <Formulario />
    </>
  ); }
