import React, { useCallback, useRef, useEffect, Fragment } from "react";
import { upload } from "@testing-library/user-event/dist/upload";
import { MdUploadFile } from "react-icons/md";
import upload1 from "assets/svg/downloadicon.svg";
import "../uploadview/css/uploadview.css";
import { Link } from "react-router-dom";
import { useDropzone } from "react-dropzone";

import { Dialog, Transition } from "@headlessui/react";
import logo from "assets/img/printgo/logo_black.svg";
import { pdfjs } from "react-pdf";
import { AiOutlineCheck, AiOutlineDelete } from "react-icons/ai";
import { PrintSettings } from "./PrintSettings";
import Swal from "sweetalert2";




const ListItem = (props) => {
  const [isShowing, setIsShowing] = React.useState(false);  

  return (
    <li
      onClick={(e) => {
        e.stopPropagation()
        if (props.preview !== props.file) {
          props.setPreview("");
          setTimeout(() => props.setPreview(props.file), 100);
        }
      }}
    >
      <div className="file-item flex justify-between border-b-2 bg-white px-2 py-4 hover:cursor-pointer hover:bg-gray-50">
        <span>{props.file.name}</span>
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            props.removeFileList(props.file)
          }}
          onMouseEnter={() => setIsShowing(true)}
          onMouseLeave={() => setIsShowing(false)}
        >
          {!isShowing && (
            <AiOutlineCheck className="h-6 w-6 " color="#32D74B" />
          )}
          <Transition show={isShowing}>
            <AiOutlineDelete className="h-6 w-6 " color="#FF453A" />
          </Transition>
        </button>
      </div>
    </li>
  );
};



const Modal = (props) => {
  const [preview, setPreview] = React.useState(props.pdfFile[0] || "");
  const [files, setFiles] = React.useState(props.pdfFile || []);
  const [color, setColor] = React.useState("Grayscale");
  const cancelButtonRef = useRef(null);


  const removeFileList = (file) => {
    let newFiles = files.filter((item) => file.name !== item.name)
    setFiles(newFiles);
    if (!!newFiles.length) {
      setPreview("");
      setTimeout(() => {
        setPreview(newFiles[0]);
      }, 100)
    } else {
      setPreview("");
    }
  }

  React.useEffect(() => {
    setPreview(props.pdfFile[0]);


    console.log("=============props.pdfFile=======================");
    console.log(props.pdfFile);
    console.log("=========props.pdfFile===========================");
    setFiles(props.pdfFile);
  }, [props.pdfFile]);



  let colorMode = {
    Grayscale: "luminosity",
    Color: "initial",
  };


  let pdfContainer = {
    width: "100%",
    borderRadius: 10,
    marginLeft: 10,
    marginRight: 10,
    mixBlendMode: colorMode[color],
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50"
        initialFocus={cancelButtonRef}
        onClose={() => {}}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto bg-[#F5F5F5]">
          <div className="flex min-h-full items-end justify-center  text-center sm:items-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full transform overflow-hidden shadow-xl  transition-all">
                <div className="flex  items-end justify-center bg-[#F5F5F5] p-4 text-center sm:items-center sm:p-0">
                  <div
                    className="flex min-w-full flex-row justify-between"
                    style={{ minHeight: "100vh" }}
                  >
                    <div className="mx-1 basis-1/5 bg-white">
                      <div className="logo-container flex justify-center border-b-2 py-10">
                        <img className="w-[60%]" src={logo} alt="PrintGo" />
                      </div>
                      <div className="list-file-container">
                        {!!files.length && (
                          <ul>
                            {files.map((file, key) => (
                              <ListItem
                                file={file}
                                preview={preview}
                                setPreview={setPreview}
                                setFiles={setFiles}
                                key={key}
                                removeFileList={removeFileList}
                              />
                            ))}
                          </ul>
                        )}
                      </div>
                      <DropLite
                        files={files}
                        setFiles={setFiles}
                        setPreview={setPreview}
                      />
                    </div>
                    <div className="mx-1 my-4  w-full grow basis-1/2  flex-col justify-center rounded-[20px] bg-white py-5">
                      <h3 className="pdf-name mb-2">
                        Vista previa {!!preview.name && `- ${preview.name}`}
                      </h3>
                      <div className="preview-container flex min-h-[95%] flex-1 justify-center ">
                        {!!preview && (
                          <embed
                            src={`${URL.createObjectURL(
                              preview
                            )}#toolbar=0&scrollbar=0&navpanes=0`}
                            style={pdfContainer}
                          />
                        )}
                      </div>
                    </div>
                    <div className="mx-1 my-4 basis-1/4 flex-col justify-center rounded-[20px] bg-white py-5">
                      <PrintSettings
                        cancelCallback={props.setOpen}
                        nfiles={files.length}
                        setColor={setColor}
                        files={files}
                      />
                    </div>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

function PdfPreviewer() {
  const [open, setOpen] = React.useState(false);
  const [pdfFile, setPdfFile] = React.useState(null);

  const onDrop = (acceptedFiles) => {
    if (!!acceptedFiles.length) {
      setPdfFile(acceptedFiles);
      setOpen(true);
    }
  };

  const onDropRejected = (acceptedFiles) => {
    Swal.fire("Un momento", "Por el momento solo se permiten archivos de tipo PDF, convierte tu arcihvo y vuelve a intentarlo", "warning");
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  return (
    <div className="mt-5">
      <div {...getRootProps()} style={{}}>
        <input {...getInputProps()} />
        <div className="rounded-[20px] bg-white p-[20px]">
          <div className="border-sky-500 drop-container rounded-[13px] border-2 border-dashed bg-[#F5F5F5]">
            <div className="flex min-h-[30vh] min-w-[30vw] items-center justify-center ">
              <div className="flex flex-col items-center justify-center ">
                <img
                  className="mb-8 h-[70px] w-[70px]"
                  alt="icon"
                  src={upload1}
                />
                <p className="title-dropdown-custom mb-1">
                  {isDragActive
                    ? "Suelta, lo estas apretando demasiado"
                    : "Presiona o arrastra tus archivos"}
                </p>
                <p className="">Sube tu PDF</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {pdfFile && <Modal open={open} setOpen={setOpen} pdfFile={pdfFile} />}
    </div>
  );
}

function DropLite(props) {
  
  const onDrop = (acceptedFiles) => {    
    props.setFiles([...props.files, ...acceptedFiles]);
    props.setPreview("")
    setTimeout(() => props.setPreview(acceptedFiles[0]), 100);
  };
  const onDropRejected = (acceptedFiles) => {
    Swal.fire("Un momento", "Por el momento solo se permiten archivos de tipo PDF, convierte tu arcihvo y vuelve a intentarlo", "warning");
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: {
      "application/pdf": [".pdf"],
    },
  });

  return (
    <div className="mt-5">
      <div {...getRootProps()} style={dropzoneStyles}>
        <input {...getInputProps()} />
        <p>
          {isDragActive
            ? "Suelta, lo estas apretando demasiado"
            : "Presiona o arrastra tus archivos"}
        </p>
      </div>
    </div>
  );
}

const dropzoneStyles = {
  border: "2px dashed #ccc",
  borderRadius: "4px",
  padding: "20px",
  textAlign: "center",
  cursor: "pointer",
};


const Uploadview = () => {

  return <PdfPreviewer />;
};

export { PdfPreviewer };
export default Uploadview;
