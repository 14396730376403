/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import routes from "routes.js";

import logo from "assets/img/printgo/printgo-logo.png"
import DashIcon from "components/icons/DashIcon";
import React from "react";
import { StoreContext } from "store/StoreProvider";
import { types } from "store/Auth";
import { Link } from "react-router-dom";
import { routesUser } from "routes";

const Sidebar = ({ open, onClose }) => {

  const [store, dispatch] = React.useContext(StoreContext);

  const loggout= () => {
    dispatch({ type: types.authLogout });
    window.location = "/auth/sign-in";
  }


  const getUserRoutes = () => {
    let type = store.user.user_type;
    switch (Number(type)) {      
      case 3:
        return routesUser;        
        break;
    
      default:
        return routes
        break;
    }    
  }
 
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-white pb-10 shadow-2xl shadow-white/5 transition-all dark:!bg-navy-800 dark:text-white md:!z-50 lg:!z-50 xl:!z-0 ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute right-4 top-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-[56px] my-[10px] flex items-center`}>
        <div className="ml-1 mt-1 h-2.5 font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white">
          <img className="w-20" src={logo} alt="PrintGo" />
        </div>
      </div>
      <div className="mb-7 mt-[58px] h-px bg-gray-300 dark:bg-white/30" />
      {/* Nav item */}

      <ul className="mb-auto pt-1">
        <Links routes={getUserRoutes()} />
        <a className="cursor-pointer" onClick={loggout}>
          <div className="relative mb-3 flex hover:cursor-pointer">
            <li className="my-[3px] flex cursor-pointer items-center px-8">
              <span className="font-medium text-gray-600">
                <DashIcon />
              </span>
              <p className={`leading-1 ml-4 flex font-medium text-gray-600`}>
                Salir
              </p>
            </li>
          </div>
        </a>
      </ul>

      {/* Free Horizon Card */}
      <div className="flex justify-center">{/* <SidebarCard /> */}</div>

      {/* Nav item end */}
    </div>
  );
};

export default Sidebar;
