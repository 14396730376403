import React from "react";
import map from "assets/img/mapa.png";
import printGo from "assets/svg/Ellipse107.svg";
import { useNavigate, useParams } from "react-router-dom";
import { getDivision } from "store/sagas/Divisions";
import { Print_sede } from "views/admin/headquarters/components/Print_sede";
import { Employees } from "views/admin/dashboard/components/Employees";
import { updateDivision } from "store/sagas/Divisions";
import Swal from "sweetalert2";
import { deleteDivision } from "store/sagas/Divisions";
import { getHeadquarter } from "store/sagas/Headquarter";

export const ShowDivision = () => {
  let { id } = useParams();
  const [division, setDivision] = React.useState({});
  const [headquarter, setHeadquarter] = React.useState({});
  const [employees, setEmployees] = React.useState([]);
  const [selectedPrinters, setSelectedPrinters] = React.useState([]);

  React.useEffect(() => {
    getDivision({ _id: id }).then((res) => {
      getHeadquarter({ _id: res.Division[0].headquarterID }).then(
        (resHeadquarter) => {
          setDivision(res.Division[0]);
          setHeadquarter(resHeadquarter);
          setEmployees(res.Employees);
          let selected = res.printers.map(({ _id }) => _id);
          setSelectedPrinters(selected);
        }
      );
    });
  }, []);

  const getDivisionInfo = () => {
     getDivision({ _id: id }).then((res) => {
       setDivision(res.Division[0]);
       let selected = res.printers.map(({ _id }) => _id);
       setSelectedPrinters(selected);
     });
  };

  const navigate = useNavigate();

  const handleDelte = () => {
    Swal.fire({
      title: "Eliminar",
      text: "¿Quieres eliminar esta división?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "ACEPTAR",
      cancelButtonText: "CANCELAR",
    }).then((result) => {
      if (result.isConfirmed) {
        let obj = {
          _id: division?._id,
        };
        deleteDivision(obj).then(() => {
          Swal.fire("Eliminada!", "", "success");
          navigate(`/admin/divisions/`);
        });
      }
    });
  };

  const handleStatus = () => {
    let obj = {
      _id: division?._id,
      status: !division?.status,
    };
    updateDivision(obj).then(() => {
      Swal.fire({
        title: "Muy bien",
        text: "División actualizada",
        icon: "success",
        confirmButtonText: "Ok",
      }).then((result) => {
        setDivision(false);
        getDivision({ _id: id }).then((res) => {
          setDivision(res.Division[0]);
          setEmployees(res.Employees);
          setSelectedPrinters(res.printers);
        });
      });
    });
  };

  const handleUrl = () => {
    let url = `https://business.printgo.app/auth/register/${division.url}`;
    Swal.fire({
      title: `<strong>Registro: <span className="text-cyan" ><u className="text-cyan" style="color: #21d4fd;">${url}</u></span></strong>`,
      icon: "info",
      html: "Copia la <b>url de registro</b> de arriba para compartirla con todos los usuarios de esta división.",
      focusConfirm: false,
      confirmButtonText: "¡Listo!",
    });
  };

  return (
    <>
      <div className="relative my-5  w-full rounded-3xl  bg-white p-1">
        <div className=" m-1 grid grid-cols-4 gap-1 bg-white">
          <div className="col-span-2 flex flex-col justify-center">
            <span className="ml-2 text-2xl font-bold  ">{division?.name}</span>
          </div>
          <div className=" col-start-4  bg-white">
            <div className=" flex justify-end ">
              <button
                onClick={handleUrl}
                className="text-md  text-bold mx-1 rounded-lg bg-teal-500 px-1 text-white hover:bg-teal-700"
              >
                VER URL DE REGISTRO
              </button>

              <button
                onClick={handleDelte}
                className="  text-md text-bold mx-1 rounded-lg bg-red-500 px-1 text-white hover:bg-red-700"
              >
                Eliminar
              </button>

              <button
                onClick={handleStatus}
                className={` text-md text-bold mx-1 rounded-lg px-1 text-white ${
                  division?.status
                    ? " bg-amber-500  hover:bg-amber-700"
                    : "bg-green-500 hover:bg-green-700"
                }`}
              >
                {division?.status ? "Inhabilitar" : "Habilitar"}{" "}
              </button>
            </div>
          </div>
          <div
            className="realive first-letter col-span-4 h-64 rounded-3xl  bg-cover  bg-center  "
            style={{ backgroundImage: `url(${map})` }}
          >
            <div className="absolute bottom-0 ml-5">
              <div className="flex flex-col content-center items-center justify-center ">
                <img className="h-50 w-50 rounded-full" src={printGo} />
                <span
                  className={`text-lg font-bold leading-normal ${
                    division?.status ? " text-green-500" : "text-red-500"
                  }`}
                >
                  {division?.status ? "Activo" : "Inactivo"}
                </span>
              </div>
            </div>
          </div>
          <div className="col-span-4 col-start-3 mt-14 flex justify-end pb-2">
            <div className="flex justify-end self-end ">
              <span className="mr-10 text-sm font-bold leading-normal">
                {division?.phone}
              </span>
              <span className="mr-10 text-sm font-bold leading-normal">
                {division.email}
              </span>
              <span className="mr-3  text-sm font-bold leading-normal">
                {division.address}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-2 gap-4">
        <div>
          <Employees data={employees} />
        </div>

        <div>
          <Print_sede
            selectedPrintersData={selectedPrinters}
            printers={headquarter?.printers || []}
            idChange={id}
            getDivisionInfo={getDivisionInfo}
          />
        </div>
      </div>
    </>
  );
};
