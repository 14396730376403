import React from "react";
import { useForm } from "hooks/useForm";
import { StoreContext } from "store/StoreProvider";
import { getAllHeadquarter } from "store/sagas/Headquarter";
import { getPrintersByIds } from "store/sagas/Print";
import { newDivision } from "store/sagas/Divisions";
import Swal from "sweetalert2";
import { redirect } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { PrinterItem } from "components/PrinterItem";

function Formulario() {
  const [store] = React.useContext(StoreContext);
  const [headquarters, setHeadquaerts] = React.useState([]);
  const [printers, setPrinters] = React.useState([]);
  const [selectedPrinters, setSelectedPrinters] = React.useState([]);

  React.useEffect(() => {
    getAllHeadquarter({ id: store.user.id }).then((res) => {
      if (!!res) {
        setHeadquaerts(res.Sedes);
      }
    });
  }, []);

  const form = {
    name: "",
    address: "",
    email: "",
    phone: "",
    nPaper: "",
    headquarter: "",
  };
  const [values, handleChange] = useForm(form);

  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    let obj = {
      name: values.name,
      email: values.email,
      address: values.address,
      phone: values.phone,
      nPaper: values.nPaper,
      headquarterID: values.headquarter,
      printers: selectedPrinters,
      url: encodeURIComponent(values.name.trim().replaceAll(' ', '-').toLocaleLowerCase())
    };
    newDivision(obj).then((res) => {
      Swal.fire({
        title: "Todo listo",
        text: "Creaste una nueva división, no olvides registrar los usuarios de la nueva división",
        icon: "success",
        showDenyButton: true,
        showCancelButton: false,
        confirmButtonText: "Crear otra",
        denyButtonText: `Ver división`,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          handleChange({ name: "", value: "" }, form);
          setSelectedPrinters([]);
          setPrinters([]);
        } else if (result.isDenied) {
          navigate(`/admin/divisions/show/${res._id}`);
        }
      });
    });
  };

  const handleHeadquarter = (e) => {
    handleChange({ name: "headquarter", value: e.target.value });
    let headquarter = headquarters.find((item) => item._id === e.target.value);
    getPrintersByIds({ printers: headquarter.printers }).then((res) => {
      setPrinters(res);
      if (res.length > 0) {
        let selectedIds = res.map(({ _id }) => _id);
        setSelectedPrinters(selectedIds);
      }
    });
  };

  const printerToggle = (id) => {
    if (selectedPrinters.includes(id)) {
      let filtered = selectedPrinters.filter((item) => item !== id);
      setSelectedPrinters(filtered);
    } else {
      setSelectedPrinters([id, ...selectedPrinters]);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="m-4 grid grid-cols-2 gap-1 ">
        <div className="m-2 rounded-lg bg-white p-6 shadow-md">
          <div className="mp-4 mb-4 flex flex-row">
            <h1 className=" mr-5 font-bold text-gray-700">Nombre:</h1>
            <input
              required="required"
              type="text"
              id="name"
              name="name"
              onChange={(e) =>
                handleChange({ name: "name", value: e.target.value })
              }
              value={values.name}
              className="w-full rounded border bg-gray-100 px-3 py-2 focus:border-blue-500 focus:outline-none"
            />
          </div>

          <div className="mp-5 mb-4 flex flex-row">
            <label
              htmlFor="direccion"
              className="mr-2 block font-bold text-gray-700"
            >
              Dirección:
            </label>
            <input
              type="text"
              required="required"
              id="address"
              name="address"
              onChange={(e) =>
                handleChange({ name: "address", value: e.target.value })
              }
              value={values.address}
              className=" w-full rounded border bg-gray-100 px-3  py-2 focus:border-blue-500 focus:outline-none"
            />
          </div>

          <div className="mp-4 mb-4 flex flex-row">
            <label
              htmlFor="email"
              className="mr-9 block font-bold text-gray-700"
            >
              Email:
            </label>
            <input
              required="required"
              type="email"
              id="email"
              onChange={(e) =>
                handleChange({ name: "email", value: e.target.value })
              }
              value={values.email}
              className="w-full rounded border bg-gray-100 px-3  py-2  focus:border-blue-500 focus:outline-none"
            />
          </div>

          <div className="mp-4 mb-4 flex flex-row">
            <label
              htmlFor="telefono"
              className="mr-3 block font-bold text-gray-700"
            >
              Teléfono:
            </label>
            <input
              required="required"
              type="tel"
              id="phone"
              name="phone"
              onChange={(e) =>
                handleChange({ name: "phone", value: e.target.value })
              }
              value={values.phone}
              className="w-full rounded border bg-gray-100 px-3 py-2  focus:border-blue-500 focus:outline-none"
            />
          </div>

          <div className="mp-4 mb-4 flex flex-row">
            <label
              htmlFor="cantidadPapel"
              className="mb-2 block font-bold text-gray-700"
            >
              Cantidad de Papel:
            </label>
            <input
             
              type="number"
              id="amount_paper"
              name="amount_paper"
              onChange={(e) =>
                handleChange({ name: "nPaper", value: e.target.value })
              }
              value={values.nPaper}
              className="w-full rounded border bg-gray-100 px-3  py-2 focus:border-blue-500 focus:outline-none"
            />
          </div>
        </div>
        <div className="m-2 rounded-lg bg-white p-6 shadow-md ">
          <div className="flex flex-row">
            <label
              htmlFor="cantidadPapel"
              className="mr-2 block font-bold text-gray-700"
            >
              Sede:
            </label>
            <select
              required="required"
              type="selet"
              id="print"
              name="print"
              onChange={handleHeadquarter}
              value={values.headquarter}
              className="w-full rounded border bg-gray-100  px-3   py-2 focus:border-blue-500 focus:outline-none"
            >
              <option value="" disabled selected>
                Selecciona una sede
              </option>
              {headquarters.map((item, key) => (
                <option value={item._id} key={key}>
                  {item.name}
                </option>
              ))}
            </select>
          </div>

          <div className=" mt-5 h-[50vh] w-auto rounded-xl  bg-gray-100 p-4  ">
            <label className="mb-4">Impresoras:</label>
            <div className="printers-container mt-5 h-[40vh] overflow-y-auto">
              {printers.map((item, key) => (
                <PrinterItem
                  key={key}
                  {...item}
                  onPress={() => printerToggle(item._id)}
                  selected={selectedPrinters.includes(item._id)}
                />
              ))}
            </div>
          </div>

          <div
            className="
          mt-20
          flex justify-end "
          >
            <button
              type="reset"
              className="  focus:shadow-outline mr-4 rounded bg-gray-400 px-4 py-2 font-bold text-white hover:bg-red-700 focus:outline-none"
            >
              Cancelar
            </button>

            <button
              type="submit"
              className=" focus:shadow-outline ml-4 rounded bg-blue-400  px-4 py-2 font-bold text-white hover:bg-blue-500  focus:outline-none"
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </form>
  );
}

export default Formulario;
