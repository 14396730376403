import { api } from "./api";

export const getAllEmployesRequest = (payload) => api.post(`/get-all-employees`, payload);

export const newEmployeRequest = (payload) => api.post(`/new-employe`, payload);

export const getEmployeRequest = (payload) => api.post(`/get-profile`, payload);

export const updateProfileRequest = (payload) => api.post(`/update-profile`, payload);

export const deleteProfileRequest = (payload) => api.post(`/delete-employe`, payload);

