import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";

export const Root = () => (
  <>
    <Routes>
      <Route path="*" element={<Navigate to="/admin" replace />} />
      {/* <Route path="rtl/*" element={<RtlLayout />} /> */}
      <Route path="admin/*" element={<AdminLayout />} />
    </Routes>
  </>
);
