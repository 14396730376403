import backgroundImage from "assets/svg/Rectangle_436.png";
import Googleplay from "assets/svg/Googleplay.svg";
import AppStore from "assets/svg/AppStore.svg";
import UploadIcon from "assets/svg/upload.svg";
import { PdfPreviewer } from "views/admin/uploadview";


export const Dropzone = () => {
  return (
    <div className="dropzone-container">
      <div className="content-wrapper flex grow-0 justify-center p-5">
        <PdfPreviewer />
        {/* <div className="content align-center flex flex-col items-center justify-center bg-white px-12">
          <img
            className="h-[80px] w-[80px]"
            alt="Subir Archivos"
            src={UploadIcon}
          />
          <div className="text-wrapper-2">Carga tu documento</div>
          <p className="dropzone-description">
            DOC, PDF y XML Todos los archivos estan permitidos
          </p>
        </div> */}
      </div>
      <div
        className="overlap-group flex grow  flex-col justify-evenly"
        style={{ backgroundImage: `url(${backgroundImage})` }}
      >
        <div className="flex min-h-full flex-col justify-around p-2">
          <div className="flex flex-col">
            <div className="title mb-4">
              <div className="div">Imprime desde tu celular</div>
            </div>
            <div className="body max-w-[28vw]">
              <p className="text-wrapper">
                Descarga PrintGo app en tu dispositivo e imprime tus documentos favoritos.
              </p>
            </div>
          </div>
          <div className="flex max-w-[35%] justify-between">
            <a
              href="https://play.google.com/store/apps/details?id=com.copywork.printgo"
              target="_blank"
              rel="PrintGo Playstore"
              className="mr-2"
            >
              <img className="w-30 h-10" alt="Rectangle" src={Googleplay} />
            </a>
            <a
              href="https://apps.apple.com/co/app/printgo/id1607363460"
              target="_blank"
              rel="PrintGo Appstore"
            >
              <img className="w-30 h-10" alt="Rectangle" src={AppStore} />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
