import { api } from "./api";

export const gedDivisionsRequest = (payload) => api.post(`/getAll-divisions`, payload);

export const newDivisionRequest = (payload) => api.post(`/new-division`, payload);

export const getDivisionRequest = (payload) => api.post(`/get-division`, payload);

export const getDivisionUrlRequest = (payload) => api.post(`/get-division-url`, payload);

export const getDivisionsByHeadquarterRequest = (payload) => api.post(`/get-divisionsBySedeID`, payload);

export const updateDivisionRequest = (payload) => api.post(`/update-division`, payload);

export const deleteDivisionRequest = (payload) => api.post(`/delete-division`, payload);
