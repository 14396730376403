import React from "react";
import Card from "components/card";
import { StatusIcon } from "components/icons/StatusIcon";
import HeadquearterIcon from "assets/svg/headquearter_icon.svg";
import { Link } from "react-router-dom";
import { getAllHeadquarter } from "store/sagas/Headquarter";
import { StoreContext } from "store/StoreProvider";

export const Sedes = () => {
  const [store] = React.useContext(StoreContext);
  const [data, setData] = React.useState([]);

  React.useEffect(() => getData(), []);

  const getData = () => {
    if (!data.length) {
      getAllHeadquarter({ id: store.user.id }).then((res) => {
        if (!!res) {
          setData(res.Sedes);
        }
      });
    }
  };

  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <div className="headquarters-container flex w-full">
        <div className="card flex flex-1">
          <div className="card-header">
            <h2 className="title">Sedes</h2>
          </div>
          <div className="h-full overflow-y-auto">
            <div className="headquarters-list">
              {data.map((item, key) => (
                <div className="headquarters-item" key={key}>
                  <div className="item-left flex">
                    <img
                      src={HeadquearterIcon}
                      alt=""
                      className="h-[46px] w-[46px] rounded-full"
                    />
                    <div className="item-overlap ml-2">
                      <div className="title">{item?.name}</div>
                      <div className="subtitle">{item?.address}</div>
                    </div>
                  </div>
                  <div className="item-right flex items-center">
                    <div className="boton">
                      <div className="overlap-group">
                        <Link to={`/admin/headquarter/show/${item._id}`}>
                          <button className="button button-primary">
                            <span className="text-white">Ver sede</span>
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div className="status-container ml-2">
                      <StatusIcon />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
