import React from "react";
import { Link } from "react-router-dom";
import { Ordenes } from "./components/Ordenes";
import{OrdesList} from "./components/OrdesList"
export const Orders = () => {
  const get = (id) => {
    fetch(`${URL}`, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: {
        "Content-Type": "application/json",
      },
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: JSON.stringify({ id }),
    })
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data.message);
      })
      .catch((e) => {
        console.log(e);
        alert(e);
      });
  };

  // div className="flex w-full flex-col gap-5 pt-2">
  {
    /* <Link to="show" onClick={() => get(2)}>
  show
</Link>; */
  }
  return (
    <>
      <div className=" mt-2 rounded-3xl bg-white p-1 ">
        <span className="ml-2 text-2xl font-bold capitalize ">
         
        </span>
        <OrdesList/>
      </div>
    </>
  );
};
