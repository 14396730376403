import React from "react";
import { Link, useParams } from "react-router-dom";
import { getEmploye } from "store/sagas/Employes";
import Banner from "../components/Banner";
import General from "../components/General";
import Project from "../components/Project";
import Upload from "views/admin/profile/components/Upload";

export const ShowEmployer = () => {
  let { id } = useParams();
  const [user,setUser] = React.useState({})

  React.useEffect(() => {
    getEmploye({ id: id }).then((res) => setUser(res));
    // getUser();
  }, []);

  const getUser = () => {
    getEmploye({ id: id }).then((res) => setUser(res));
  }

  return (
    <div className="grid w-full grid-cols-2  gap-2">
      <div className="w-ful mt-3 flex h-fit flex-col ">
        <div className="col-span-3 lg:!mb-0">
          <Banner {...user} />
        </div>
        <div className=" mt-5">
          <Project {...user} getUser={getUser} />
        </div>
      </div>

      <div className="grid h-full">
        <div className="col-span-5 lg:col-span-6 lg:mb-0 3xl:col-span-4">
          <General {...user} getUser={getUser} />
        </div>
      </div>
    </div>
  );
};
