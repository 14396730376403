import React from 'react';
import {
  loginRequest,
  updateRequest,
  validateCodeRequest,
  registerEmployerRequest,
} from "api/auth";

export const loginUser = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      loginRequest(payload).then(async (res) => {
        if (res.status === 200) {
          // let resu = await res.json();          
          resolve(res);
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const registerEmployer = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      registerEmployerRequest(payload).then(async (res) => {
        if (res.status === 200) {
          let resu = await res.json();
          resolve(resu);
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const validateCode = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      validateCodeRequest(payload).then(async (res) => {
        if (res.status === 200) {
           let resu = await res.json(); 
          resolve(resu);
        } else {
          reject(res.data);
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};



export const updateUser = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      updateRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.data);
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};
