import React from "react";

// Admin Imports
import { Dashboard } from "views/admin/dashboard";
import { Headquarters } from "views/admin/headquarters";
import { ShowHeadquarter } from "views/admin/headquarters/show";
import { NewHeadquarter } from "views/admin/headquarters/new";
import { Divisions } from "views/admin/divisions";
import { ShowDivision } from "views/admin/divisions/show";
import { NewDivision } from "views/admin/divisions/new";
import { Users } from "views/admin/users";
import { NewUser } from "views/admin/users/new";
import { Orders } from "views/admin/orders";
import { ShowOrder } from "views/admin/orders/show";
import { Profile } from "views/admin/profile";
import { HiDocumentArrowUp } from "react-icons/hi2";
import { FaPeopleGroup } from "react-icons/fa6";




// Auth Imports
import SignIn from "views/auth/SignIn";

// Icon Imports
import {
  MdHome,
  MdOutlineShoppingCart,
  MdBarChart,
  MdPerson,
  MdLock,
  MdViewQuilt,
 
} from "react-icons/md";
import Uploadview from "views/admin/uploadview";
import { ShowEmployer } from "views/admin/users/show";
import { BsFillBuildingsFill } from "react-icons/bs";
import { IoLogOut } from "react-icons/io5";
import { FaClipboardList } from "react-icons/fa";
import { AiFillHome } from "react-icons/ai";
import { Register } from "views/auth/register";


const routes = [
  {
    name: "Inicio",
    layout: "/admin",
    path: "dashboard",
    icon: <AiFillHome className="h-6 w-6" />,
    component: <Dashboard />,
  },
  {
    name: "Ordenes",
    layout: "/admin",
    path: "orders",
    icon: <FaClipboardList className="h-6 w-6" />,
    component: <Orders />,
  },

  {
    name: "Detalle de Orden",
    subitem: true,
    parent: "orders",
    layout: "/admin",
    path: "show",
    component: <ShowOrder />,
  },

  {
    name: "Usuarios",
    layout: "/admin",
    icon: <FaPeopleGroup className="h-6 w-6" />,
    path: "users",
    component: <Users />,
  },
  {
    name: "Nuevo Empleado",
    subitem: true,
    parent: "users",
    layout: "/admin",
    path: "new",
    component: <NewUser />,
  },
  {
    name: "Nuevo Empleado",
    subitem: true,
    parent: "users",
    layout: "/admin",
    path: "show",
    component: <ShowEmployer />,
  },
  {
    name: "Sedes",
    layout: "/admin",
    icon: <BsFillBuildingsFill />,
    path: "headquarter",
    component: <Headquarters />,
  },
  {
    name: "Sede",
    subitem: true,
    parent: "headquarter",
    layout: "/admin",
    path: "show",
    component: <ShowHeadquarter />,
  },
  {
    name: "Nueva Sede",
    subitem: true,
    parent: "headquarter",
    layout: "/admin",
    path: "new",
    component: <NewHeadquarter />,
  },
  {
    name: "Divisiones",
    layout: "/admin",
    path: "divisions",
    icon: <MdViewQuilt className="h-6 w-6" />,
    component: <Divisions />,
    secondary: true,
  },
  {
    name: "División",
    subitem: true,
    parent: "divisions",
    layout: "/admin",
    path: "show",
    component: <ShowDivision />,
  },
  {
    name: "Nueva División",
    subitem: true,
    parent: "divisions",
    layout: "/admin",
    path: "new",
    component: <NewDivision />,
  },
  {
    name: "Perfil",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },

  {
    name: "Cargar archivo",
    layout: "/admin",
    path: "upload",
    icon: <HiDocumentArrowUp className="h-6 w-6" />,
    component: <Uploadview />,
    secondary: true,
  },

  {
    name: "Register",
    subitem: true,
    layout: "/auth",
    path: "register",
    // icon: <IoLogOut className="h-6 w-6" />,
    component: <Register />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <IoLogOut className="h-6 w-6" />,
    component: <SignIn />,
  },
];


export const routesUser = [
  {
    name: "Inicio",
    layout: "/admin",
    path: "dashboard",
    icon: <AiFillHome className="h-6 w-6" />,
    component: <Dashboard />,
  },
  {
    name: "Cargar archivo",
    layout: "/admin",
    path: "upload",
    icon: <HiDocumentArrowUp className="h-6 w-6" />,
    component: <Uploadview />,
    secondary: true,
  },
  {
    name: "Ordenes",
    layout: "/admin",
    path: "orders",
    icon: <FaClipboardList className="h-6 w-6" />,
    component: <Orders />,
  },

  {
    name: "Perfil",
    layout: "/admin",
    path: "profile",
    icon: <MdPerson className="h-6 w-6" />,
    component: <Profile />,
  },
  {
    name: "Sign In",
    layout: "/auth",
    path: "sign-in",
    icon: <IoLogOut className="h-6 w-6" />,
    component: <SignIn />,
  },
];

export default routes;
