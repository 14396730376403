import React from "react";
import "./style.css";
import { Dropzone } from "./components/Dropzone";
import { Divisions } from "./components/Divisions";
import { Sedes } from "../headquarters/components/Sedes";
import { OrdesList } from "../orders/components/OrdesList";
import { StoreContext } from "store/StoreProvider";
import { Users } from "../users";


export const Dashboard = () => {

  const [store] = React.useContext(StoreContext)
  return (
    <div className="mt-10 flex w-full flex-col gap-5 ">
      <Dropzone />
      <div className="grid grid-cols-2 gap-4">
        <div className={`${Number(store.user.user_type) === 3  ? "col-span-2" : ""}`}>
          <OrdesList />
        </div>
        {Number(store.user.user_type) !== 3 && (
            <>
              <div className="">
                <Users />
              </div>
              <div className="">
                <Sedes />
              </div>
              <div className="">
                <Divisions />
              </div>
            </>
          )}
      </div>
    </div>
  );
};
