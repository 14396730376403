import Card from "components/card";
import { StatusIcon } from "components/icons/StatusIcon";
import AddIcon from "assets/svg/add.svg";
import DivisionIcon from "assets/svg/division_icon.svg";
import { Link } from "react-router-dom";
import React from "react";
import { Dot } from "assets/svg/dot";


export const Divisions = ({ data = [] }) => {
  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <div className="headquarters-container flex w-full">
        <div className="card flex flex-1">
          <div className="card-header">
            <h2 className="title">Divisiones</h2>
            <div className="float-right flex ">
              <div className="overlap-wrapper mr-3 ">
                <Link to={"/admin/divisions"}>
                  <button className="button">
                    <span>Ver todos</span>
                  </button>
                </Link>
              </div>
              <Link to={"/admin/divisions/new"}>
                <button className="button">
                  <img src={AddIcon} alt="" className="h-[24px] w-[24px]" />
                </button>
              </Link>
            </div>
          </div>
          <div className="h-full overflow-y-auto">
            <div className="headquarters-list">
              {data.map((item, key) => (
                <Link to={`/admin/divisions/show/${item._id}`} key={key}>
                  <div className="headquarters-item">
                    <div className="item-left flex items-center">
                      <img
                        src={DivisionIcon}
                        alt=""
                        className="h-[46px] w-[46px] rounded-full"
                      />
                      <div className="item-overlap ml-2">
                        <div className="title">{item?.name}</div>
                        <div className="subtitle">{item?.address}</div>
                      </div>
                    </div>
                    <div className="item-right flex items-center">
                      <div className="status-container ml-2">
                        <div
                          className={`h-5 w-5 rounded-full ${
                            item?.status ? "bg-green-500" : "bg-amber-500"
                          } `}
                        />
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
