import React from "react";
import {
  getQueueRequest,
  uploadFileRequest,
  printOrderRequest,
  getPrintersRequest,
  getByCodeRequest,
  getPrintersByIdsRequest,
  getPrintersUserRequest,
} from "api/print";

export const getQueue = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      getQueueRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.data);
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const uploadFile = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      uploadFileRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const printOrder = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      printOrderRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.data);
        } else {
          reject(res.data);
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const getPrinters = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      getPrintersRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.data);
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};


export const getPrintersUser = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      getPrintersUserRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const getPrintersByIds = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      getPrintersByIdsRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const getByCode = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      getByCodeRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.data);
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};
