import React from "react";
import map from "./map.png";
import banner from "assets/img/profile/banner.png";
import img_sede from "./map.png";
import printGo from "assets/svg/Ellipse107.svg";
import { useParams } from "react-router-dom";
import { Print_sede } from "views/admin/headquarters/components/Print_sede";
import { getHeadquarter, updateHeadquarter } from "store/sagas/Headquarter";
import { Employees } from "views/admin/dashboard/components/Employees";
import { Divisions } from "views/admin/headquarters/components/Divisiones";
import Swal from "sweetalert2";
import { getDivisionsByHeadquarter } from "store/sagas/Divisions";


export const ShowHeadquarter = () => {
  let { id } = useParams();
  const [headquarter, setHeadquarter] = React.useState({});
  const [employees, setEmployees] = React.useState([]);
  const [selectedPrinters, setSelectedPrinters] = React.useState([]);
  const [selectedPrintersData, setSelectedPrintersData] = React.useState([]);
   const [divisions, setDivisions] = React.useState([]);

  React.useEffect(() => {
    getHeadquarter({ _id: id }).then((res) => {
      getDivisionsByHeadquarter({ _id: res.sede[0]._id }).then((divisions) => {
        setHeadquarter(res.sede[0]);
        setEmployees(res.employers);
        setSelectedPrinters(res.printers);
        let selected = res.printers.map(({_id}) => _id)
        setSelectedPrintersData(selected);
        setDivisions(divisions.division);
      });
    });
  }, []);

  const handleStatus = () => {
    let obj = {
      _id: headquarter?._id,
      status: !headquarter?.status,
    };
    updateHeadquarter(obj).then(() => {
      Swal.fire({
        title: "Muy bien",
        text: "División actualizado",
        icon: "success",
        confirmButtonText: "Ok",
      }).then((result) => {
        getHeadquarter({ _id: id }).then((res) => {
          setHeadquarter(res.sede[0]);
          setEmployees(res.employers);
          setSelectedPrinters(res.printers);
          setDivisions(res.divisions);
        });
      }, []);
    });
  };

  return (
    <>
      <div className="relative my-5  w-full rounded-3xl  bg-white p-1">
        <div className=" m-1 grid grid-cols-4 gap-1 bg-white">
          <div className="col-span-2 flex flex-col justify-center">
            <span className="ml-2 text-2xl font-bold  ">
              {headquarter?.name}
            </span>
          </div>
          <div className=" col-start-4  bg-white">
            <div className=" flex justify-end ">
              {/* <button
                onClick={handleStatus}
                className={`m-1  rounded-2xl  p-2  text-sm font-medium leading-normal text-white ${
                  headquarter?.status
                    ? " bg-amber-500 hover:bg-amber-700"
                    : "bg-green-500 hover:bg-green-700"
                }`}
              >
                {headquarter?.status ? "Inhabilitar" : "Habilitar"}
              </button> */}
            </div>
          </div>
          <div
            className="realive first-letter col-span-4 h-64 rounded-3xl  bg-cover  bg-center  "
            style={{ backgroundImage: `url(${banner})` }}
          >
            <div className="absolute bottom-0 ml-5">
              <div className="flex flex-col content-center items-center justify-center ">
                <img className="h-50 w-50 rounded-full" src={printGo} />
                {headquarter.status ? (
                  <span className="text-lg font-bold leading-normal text-green-500">
                    Activo
                  </span>
                ) : (
                  <span className="text-lg font-bold leading-normal text-red-500">
                    Inactivo
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="col-span-4 col-start-3 mt-14 flex justify-end pb-2">
            <div className="flex justify-end self-end ">
              <span className="mr-10 text-sm font-bold leading-normal">
                {headquarter?.phone}
              </span>
              <span className="mr-10 text-sm font-bold leading-normal">
                {headquarter.email}
              </span>
              <span className="mr-3  text-sm font-bold leading-normal">
                {headquarter.address}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 gap-4">
        <div>
          <Divisions data={divisions} />
        </div>
        <div>
          <Employees data={employees} />
        </div>

        <div>
          <Print_sede
            printers={selectedPrinters}
            selectedPrintersData={selectedPrintersData}
            upd={false}
          />
        </div>
      </div>
    </>
  );
};
