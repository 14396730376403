import * as React from "react";

const ASYNC_KEY = "@app_prtgo_storage";

const storeData = (value) => {
  return new Promise((resolve, reject) => {
    try {
      console.log("Saving...");
      resolve(localStorage.setItem(ASYNC_KEY, JSON.stringify(value)));
    } catch (e) {
      // saving error
      reject(e.error);
    }
  });
};

const getData = (item) => {
  return new Promise((resolve, reject) => {
    try {
      if (localStorage.getItem(item)) {
        resolve(JSON.parse(localStorage.getItem(item)));
      } else {
        resolve(false);
      }
    } catch (e) {
      // error reading value
    }
  });
};

const types = {
  rehidrate: "REHIDRATE_STORE",
};

const AsyncReducer = (state, action) => {
  switch (action.type) {
    case types.rehidrate:
      return {
        ...state,
        ...action.payload,
        rehidrate: true,
      };
    default:
      return state;
  }
};

export { AsyncReducer, types, ASYNC_KEY, getData, storeData };
