import InputField from "components/fields/InputField";
import React, { useState, useContext } from "react";
import { StoreContext } from "store/StoreProvider";
import { types } from "../../../store/Auth";
import { registerEmployer, validateCode } from "store/sagas/Auth";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { getDivisionUrl } from "store/sagas/Divisions";

export const Register = () => {
  let { url } = useParams();
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const [division, setDivision] = useState({});
  const [code, setCode] = useState("");
  const [validate, setValidate] = useState(false);
  const [store, dispatch] = useContext(StoreContext);

  //comentario test
  const sendNude = () => {
    let obj = {
      user_data: {
        name: name,
        email: value,
        printers: division.printers,
        divisionID: division._id,
        nPaper: division.nPaper,
        user_type: 3,
      },
    };

    registerEmployer(obj)
      .then((res) => {
        if (!!res.message) {
          Swal.fire("Un momento...", res.message, "warning");
        } else {
          Swal.fire({
            title: "Código enviado",
            text: "Encuentra tu código de acceso en el correo ingresado.",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            setValidate(true);
          });
        }
      })
      .catch((e) => {
        console.log(e);
        alert(e);
      });
  };

  const validateNude = () => {
    validateCode({ user_data: value, validation_code: code })
      .then((res) => {
        if (!!res.message) {
          Swal.fire("Un momento…", res.message, "warning");
        } else {
          dispatch({ type: types.authLogin, payload: res.data });
        }
      })
      .catch((e) => {
        console.log(e);
        alert(e);
      });
  };

  React.useEffect(() => {
    getDivisionUrl({ url }).then((res) => {
      if (!!res.length) {
        setDivision(res[0]);
      } else {
        window.location = "/auth";
      }
    });
  }, []);

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Registro {division.name}
        </h4>

        <form
          action=""
          onSubmit={(e) => {
            e.preventDefault();
            if (!!validate) {
              validateNude();
            } else {
              sendNude();
            }
          }}
        >
          {!!validate ? (
            <>
              <p className="mb-9 ml-1 text-base text-gray-600">
                Ingresa el código de validación que te llego a tu correo
              </p>

              {/* VALIDATE VODE */}
              <InputField
                extra="mb-3"
                label="Código de validación"
                placeholder="****"
                type="text"
                require={true}
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </>
          ) : (
            <>
              <p className="mb-9 ml-1 text-base text-gray-600">
                Ingresa con tu correo
              </p>

              {/* Name */}
              <InputField
                variant="auth"
                extra="mb-3"
                require={true}
                label="Nombre*"
                placeholder="Maria Paula"
                id="name"
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />

              {/* Email */}
              <InputField
                variant="auth"
                extra="mb-3"
                require={true}
                label="Email*"
                placeholder="mail@tuempresa.com"
                id="email"
                type="email"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </>
          )}

          {/* Checkbox */}
          <div className="mb-4 flex items-center justify-between px-2">
            {!!validate && (
              <a
                className="cursor-pointer text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                onClick={() => setValidate(false)}
              >
                Volver a enviar
              </a>
            )}
          </div>
          <button
            // onClick={(e) => {
            //   e.preventDefault();
            //   if (!!validate) {
            //     validateNude();
            //   } else {
            //     sendNude();
            //   }
            // }}
            className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            {!!validate ? "Validar código" : "Entrar"}
          </button>
        </form>
      </div>
    </div>
  );
};
