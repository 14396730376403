import { updateDivisionRequest } from "api/divisions";
import { getDivisionsByHeadquarterRequest } from "api/divisions";
import {
  gedDivisionsRequest,
  newDivisionRequest,
  getDivisionRequest,
  deleteDivisionRequest,
  getDivisionUrlRequest,
} from "api/divisions";

export const deleteDivision = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      deleteDivisionRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const gedDivisions = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      gedDivisionsRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};
export const getDivision = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      getDivisionRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const getDivisionUrl = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      getDivisionUrlRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const getDivisionsByHeadquarter = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      getDivisionsByHeadquarterRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const newDivision = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      newDivisionRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const updateDivision = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      updateDivisionRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};
