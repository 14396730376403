import React from "react";
import { Link } from "react-router-dom";
import Card from "components/card";
import AddIcon from "assets/svg/add.svg";
import DivisionIcon from "assets/svg/division_icon.svg";
import { StatusIcon } from "components/icons/StatusIcon";

import { gedDivisions } from "store/sagas/Divisions";
import { StoreContext } from "store/StoreProvider";
import { Loader } from "components/loader";

export const Divisions = () => {
  const [store] = React.useContext(StoreContext);
  const [data, setData] = React.useState([]);

  const get = (id) => {
    gedDivisions({ id: store.user.id }).then((result) => {
      setData(result);
    });
  };

  React.useEffect(() => get(), []);

  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <div className="headquarters-container flex w-full">
        <div className="card flex flex-1">
          <div className="card-header">
            <h2 className="title"> Divisiones</h2>
            <div className="float-right flex ">
              <Link to={"/admin/divisions"}>
                <button className=" button button-primary text-white ">
                  ver todos
                </button>
              </Link>

              <Link to={"/admin/divisions/new"}>
                <button className="button ml-2">
                  <img src={AddIcon} alt="" className="h-[24px] w-[24px]" />
                </button>
              </Link>
            </div>
          </div>
          <div className="h-full overflow-y-auto">
            <div className="headquarters-list">
              {data.map((item, key) => (
                <div className="headquarters-item" key={key}>
                  <div className="item-left flex">
                    <img
                      src={DivisionIcon}
                      alt=""
                      className="h-[46px] w-[46px] rounded-full"
                    />
                    <div className="item-overlap ml-2">
                      <div className="title">{item?.name}</div>
                      <div className="subtitle">{item?.address}</div>
                    </div>
                  </div>
                  <div className="item-right flex items-center">
                    <div className="boton">
                      <div className="overlap-group">
                        <Link to={`/admin/divisions/show/${item._id}`}>
                          <button className="button button-primary">
                            <span className="text-white">Ver</span>
                          </button>
                        </Link>
                      </div>
                    </div>
                    <div className="status-container ml-2">
                      <StatusIcon />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
