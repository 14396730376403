
export const BASE_URL = `${process.env.REACT_APP_URL_BASE}`;

export const api = {
  postFormData: function (url, payload) {
    return new Promise((resolve, reject) => {
      fetch(`${BASE_URL}${url}`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          Accept: "application/json",
          // "Content-Type": "multipart/form-data",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: payload,
      })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  post: function (url, payload) {
    return new Promise((resolve, reject) => {
      fetch(`${BASE_URL}${url}`, {
        method: "POST",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(payload),
      })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  put: function (url, payload) {
    return new Promise((resolve, reject) => {
      fetch(`${BASE_URL}${url}`, {
        method: "PUT",
        mode: "cors",
        cache: "no-cache",
        credentials: "same-origin",
        headers: {
          "Content-Type": "application/json",
        },
        redirect: "follow",
        referrerPolicy: "no-referrer",
        body: JSON.stringify(payload),
      })
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
  get: function (url, payload) {
    let request = new URLSearchParams(payload);
    return new Promise((resolve, reject) => {
      let URL = !!payload
        ? `${BASE_URL}${url}?${request}`
        : `${BASE_URL}${url}`;
      fetch(URL)
        .then((res) => resolve(res))
        .catch((err) => reject(err));
    });
  },
};
