import { PrinterItem } from "components/PrinterItem";
import Card from "components/card";
import React from "react";
import { StoreContext } from "store/StoreProvider";
import { updateProfile } from "store/sagas/Employes";
import Swal from "sweetalert2";

const General = (props) => {
  const [store] = React.useContext(StoreContext);
  const [selectedPrinters, setSelectedPrinters] = React.useState([]);
  React.useEffect(() => {
    if (!!props.printers) {
      let selected = props.printers.map(({ _id }) => _id);
      setSelectedPrinters(selected);
    }
  }, [props.printers]);

  const handlePrinters = (id) => {

    if (store.user.user_type !== 3 ) {
      let filtered;
      if (selectedPrinters.includes(id)) {
        filtered = selectedPrinters.filter((item) => item !== id);
      } else {
        filtered = [id, ...selectedPrinters];
      }
      let obj = {
        _id: props._id,
        printers: filtered,
      };
      updateProfile(obj).then(() => {
        Swal.fire({
          title: "Print & Go!",
          text: "Impresora de usuario actualizada",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          props.getUser();
        });
      });
    }
  };

  return (
    <Card extra={"w-full h-full p-3"}>
      {/* Header */}
      <div className="mb-8 mt-2 w-full">
        <h4 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
          Empresa
        </h4>
        <div className="mt-5 px-5">
          <div className="mb-6 md:flex md:items-center">
            <div className="md:w-1/3">
              <label
                className="mb-1 block pr-4 font-bold text-gray-500 md:mb-0 md:text-left"
                htmlFor="inline-full-name"
              >
                Nombre
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight text-gray-700 focus:border-purple-500 focus:bg-white focus:outline-none"
                id="inline-full-name"
                readOnly
                value={props?.division?.name}
              />
            </div>
          </div>
          <div className="mb-6 md:flex md:items-center">
            <div className="md:w-1/3">
              <label
                className="mb-1 block pr-4 font-bold text-gray-500 md:mb-0 md:text-left"
                htmlFor="inline-email"
              >
                Dirección
              </label>
            </div>
            <div className="md:w-2/3">
              <input
                className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight text-gray-700 focus:border-purple-500 focus:bg-white focus:outline-none"
                id="inline-email"
                readOnly
                value={props?.division?.address}
              />
            </div>
          </div>
        </div>
      </div>
      {/* Cards */}
      <h3 className="px-2 text-xl font-bold text-navy-700 dark:text-white">
        Impresoras habilitadas
      </h3>
      <div className="printers-container mt-5 max-h-[45vh] overflow-y-auto rounded-md bg-gray-200 py-5 ">
        {!!props?.division?.printers && (
          <>
            {props?.division?.printers.map((item, key) => (
              <PrinterItem
                key={key}
                {...item}
                selected={selectedPrinters.includes(item._id)}
                onPress={() => handlePrinters(item._id)}
              />
            ))}
          </>
        )}
      </div>
    </Card>
  );
};

export default General;
