import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AuthLayout from "layouts/auth";
import { StoreContext } from "store/StoreProvider";

export const Auth = () => {
  const [store] = React.useContext(StoreContext);

  return (
    <>
      <Routes>
        {!store?.user?.auth && (
          <Route path="*" element={<Navigate to="/auth/sign-in" />} />
        )}
        <Route path="auth/*" element={<AuthLayout />} />
      </Routes>
    </>
  );
};
