import { api } from "./api";

export const loginRequest = (payload) => api.post(`/loginpwa`, payload);

export const registerEmployerRequest = (payload) => api.post(`/register-employer`, payload);

export const validateCodeRequest = (payload) => api.post(`/verificationpwa`, payload);

export const updateRequest = (payload) =>
  api.post(`/update-user`, payload, {
    headers: {
      Authorization: `Bearer ${payload.token}`,
    },
  });
