import React from 'react';

export const StatusIcon= (props) =>  {    
    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
      >
        <circle cx="10" cy="10.2344" r="10" fill={props.color || "#32D74B"} />
      </svg>
    );
}

