import React from "react";
import { PrinterItem } from "components/PrinterItem";
import Card from "components/card";
import Swal from "sweetalert2";
import { updateDivision } from "store/sagas/Divisions";

const prin_info = {
  model_marca: "Capital Work / Epson 3389M",

  addres: "Direccion: Av #36, Bogota, Colombia",
  color: "ByN, Color",
  type_pg: "A4, Carta, Oficio, A3",
};

export const Print_sede = ({ printers = [],selectedPrintersData=[], idChange, getDivisionInfo, upd=true }) => {
  const [selectedPrinters, setSelectedPrinters] = React.useState([]);

  React.useEffect(() => {    
    if (!!selectedPrintersData.length) {
    }
    setSelectedPrinters(selectedPrintersData);
  }, [selectedPrintersData]);

  const handlePrinters = (id) => {
    if (upd) {
      let filtered;
      if (selectedPrinters.includes(id)) {
        filtered = selectedPrinters.filter((item) => item !== id);
      } else {
        filtered = [id, ...selectedPrinters];
      }
      let obj = {
        _id: idChange,
        printers: filtered,
      };
      updateDivision(obj).then(() => {
        Swal.fire({
          title: "Print & Go!",
          text: "Impresora actualizada",
          icon: "success",
          confirmButtonText: "Ok",
        }).then((result) => {
          if (!!getDivisionInfo) {
            getDivisionInfo()
          }
        });
      });
    }
  };

  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <div className="h-full rounded-lg bg-white p-4 ">
        <div className="grid grid-cols-12">
          <span className="text-neutral-800 col-span-10 text-2xl font-bold leading-loose">
            Impresoras habilitadas
          </span>
        </div>
        <div className="relative m-2 max-h-[60vh] overflow-y-auto rounded-xl bg-gray-50 p-5 ">
          {printers.map((item, key) => (
            <PrinterItem
              {...item}
              key={key}
              onPress={() => handlePrinters(item._id)}
              selected={selectedPrinters.includes(item._id)}
            />
          ))}
        </div>
      </div>
    </Card>
  );
};
