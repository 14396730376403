import { StatusIcon } from "components/icons/StatusIcon";
import { Link } from "react-router-dom";
import ColumnsTable from "views/admin/tables/components/ColumnsTable";
import AddIcon from "assets/svg/add.svg";
import OrderIcon from "assets/svg/order_icon.svg";
import { getAllOrdes } from "store/sagas/Orders";
import React from "react";
import { StoreContext } from "store/StoreProvider";
import Card from "components/card";
import { gedDivisions } from "store/sagas/Divisions";
import { types } from "store/Print";
import { EmptyList } from "components/EmptyList";
import { getOrdersByUser } from "store/sagas/Orders";

export const OrdesList = () => {
  const [store, dispatch] = React.useContext(StoreContext);

  const get = (id) => {
    
    if (store.user.user_type === 1) {      
      getOrdersByUser({ id: store.user.id }).then((result) => {
        dispatch({ type: types.getOrders, payload: result });
      });
    } else {      
      getAllOrdes({ id: store.user.id }).then((result) => {
        dispatch({ type: types.getOrders, payload: result });
      });
    }
  };

  React.useEffect(() => get(), []);

  return (
    <Card extra={"w-full pb-10 p-4 h-full"}>
      <div className="headquarters-container flex w-full">
        <div className="card flex flex-1">
          <div className="card-header">
            <h2 className="title">Ordenes</h2>
            <div className="float-right flex ">
              <Link to={"/admin/upload"}>
                <button className="button">
                  <img src={AddIcon} alt="" className="h-[24px] w-[24px]" />
                </button>
              </Link>
            </div>
          </div>
          <div className="h-full overflow-y-auto">
            <div className="headquarters-list">
              {!!store.orders.length ? (
                <>
                  {store.orders.map((item, key) => (
                    <div className="headquarters-item" key={key}>
                      <div className="item-left flex">
                        <img
                          src={OrderIcon}
                          alt=""
                          className="h-[46px] w-[46px] rounded-full"
                        />
                        <div className="item-overlap ml-2">
                          <div className="title">
                            {item?.order?.files[0].originalname}
                          </div>
                          <div className="subtitle">
                            {item?.order?.created_at}
                          </div>
                        </div>
                      </div>
                      <div className="item-right flex items-center">
                        <div className="boton">
                          <div className="overlap-group">
                            {/* <Link to={`/admin/divisions/show/${item._id}`}>
                          <button className="button button-primary">
                            <span className="text-white">Ver</span>
                          </button>
                        </Link> */}
                          </div>
                        </div>
                        <div className="status-container ml-2">
                          <StatusIcon />
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              ) : (
                <>
                  <EmptyList text="Aun no tienes ordenes" />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Card>
  );
};
