import {
          getEmployeRequest,
          deleteProfileRequest,
          updateProfileRequest,
          newEmployeRequest,
          getAllEmployesRequest,} from "api/employes";

export const deleteEmployes = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      deleteProfileRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const getAllEmployes = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      getAllEmployesRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const newEmploye = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      newEmployeRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const getEmploye = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      getEmployeRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};

export const updateProfile = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      updateProfileRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};
