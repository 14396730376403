import * as React from "react";
import {AsyncReducer, types as typesAsync, storeData} from './AsyncStore';
import { AuthReducer, types as typesAuth } from "./Auth";
import { PrintReducer, types as typesPrint } from "./Print";


const initialStore = {
  rehidrate: false,
  user: {
    auth: false,
  },
  orders: [],
};

const storeReducer = (state, action) => {
  //combine reducers
  if (Object.values(typesAsync).indexOf(action.type) >= 0) {
    return AsyncReducer(state, action);
  } else if (Object.values(typesAuth).indexOf(action.type) >= 0) {
    let store = AuthReducer(state, action);
    storeData(store)
    return store;
  } else if (Object.values(typesPrint).indexOf(action.type) >= 0) {
    let store = PrintReducer(state, action);
    storeData(store)
    return store;
  // } else if (Object.values(typesWallet).indexOf(action.type) >= 0) {
  //   let store = WalletReducer(state, action);
  //   storeData(store)
  //   return store;
  // } else if (Object.values(typesSearch).indexOf(action.type) >= 0) {
  //   let store = SearchReducer(state, action);
  //   storeData(store);
  //   return store;
  } else {
    return state;
  }

}

export { initialStore }
export default storeReducer