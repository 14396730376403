import "../headquarters/headquarter.css";
import { Sedes } from "./components/Sedes";


export const Headquarters = () => {
 
  return (
    <div className="flex w-full flex-col gap-5 pt-2">
      <Sedes/>
    </div>
  );
};
