import React from "react";
import { StoreContext } from "store/StoreProvider";
import { Root } from "router/Root";
import { Auth } from "router/Auth";

const App = () => {
  const [store] = React.useContext(StoreContext);

  if (!store) return null; 

  return (
    <>{!!store && !!store?.user && !!store?.user?.auth ? <Root /> : <Auth />}</>
  );
};

export default App;
