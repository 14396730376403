import React from 'react';
import {api} from './api';

export const getQueueRequest = (payload) => api.post(`/get-orders`, payload);

export const uploadFileRequest = (payload) => api.postFormData(`/upload`, payload);

export const printOrderRequest = (payload) => api.post(`/print`, payload);

export const getPrintersRequest = (payload) => api.post(`/get-printers`, payload);

export const getPrintersByIdsRequest = (payload) => api.post(`/get-printers`, payload);

export const getByCodeRequest = (payload) => api.post(`/get-code-qr`, payload);

export const getPrintersUserRequest = (payload) => api.post(`/get-printers-user`, payload);
