
import {
  getAllHeadquarterRequest,
  updateHeadquarterRequest,
  getHeadquarterRequest,
} from "api/headquarter";




export const updateHeadquarter = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      updateHeadquarterRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};


export const getAllHeadquarter = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      getAllHeadquarterRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};
export const getHeadquarter = (payload) => {
  return new Promise((resolve, reject) => {
    try {
      getHeadquarterRequest(payload).then((res) => {
        if (res.status === 200) {
          console.log(res);
          resolve(res.json());
        } else {
        }
      });
    } catch (error) {
      console.log(error);
      reject(error);
    }
  });
};
