import InputField from "components/fields/InputField";
import { useState, useContext } from "react";
import { StoreContext } from "store/StoreProvider";
import { types } from "../../store/Auth";
import { loginUser, validateCode } from "store/sagas/Auth";
import Swal from "sweetalert2";

export default function SignIn() {
  const [value, setValue] = useState("");
  const [code, setCode] = useState("");
  const [validate, setValidate] = useState(false);
  const [store, dispatch] = useContext(StoreContext);

  //comentario test
  const sendNude = () => {
    let obj = { user_data: value };
    loginUser(obj)
      .then(async (res) => {
        let text = await res.text();
        if (text === `{"message":"Ingrese email valido o numero telefonico"}`) {
          Swal.fire("Un momento...", "Ingrese email valido", "warning");
        } else if (
          text ===
          `Su correo electronico no está registrado, porfavor contacte con soporte`
        ) {
          Swal.fire({
            title: "Un momento...",
            text: "Su correo electrónico no está registrado, por favor contacte con nuestro soporte para ayuda.",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#32D74B",
            cancelButtonColor: "#0F81FE",
            confirmButtonText: "SOPORTE",
            cancelButtonText: "OK",
          }).then((result) => {
            if (result.isConfirmed) {
              window.open("https://wa.me/573057232113");
            }
          });

        } else if (text === `"User exist: code sent by email"`) {
          Swal.fire({
            title: "Código enviado",
            text: "Encuentra tu código de acceso en el correo ingresado.",
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            setValidate(true);
          });
          
        } else {
          Swal.fire("Un momento...", "Error desconocido", "warning");
        }
      })
      .catch((e) => {
        console.log(e);
        // alert(e);
      });
  };

  const validateNude = () => {
    validateCode({ user_data: value, validation_code: code })
      .then((res) => {
        if (!!res.message) {
          Swal.fire("Parece que...", res.message, "warning");
        } else {
          dispatch({ type: types.authLogin, payload: res.data });
        }
      })
      .catch((e) => {
        console.log(e);
        alert(e);
      });
  };

  return (
    <div className="mb-16 mt-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
      {/* Sign in section */}
      <div className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
        <h4 className="mb-2.5 text-4xl font-bold text-navy-700 dark:text-white">
          Bienvenido
        </h4>

        <form
          action=""
          onSubmit={(e) => {
            e.preventDefault();
            if (!!validate) {
              validateNude();
            } else {
              sendNude();
            }
          }}
        >
          {!!validate ? (
            <>
              <p className="mb-9 ml-1 text-base text-gray-600">
                Ingresa el código de validación que te llego a tu correo
              </p>

              {/* VALIDATE VODE */}
              <InputField
                extra="mb-3"
                label="Código de validación"
                placeholder="****"
                type="text"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </>
          ) : (
            <>
              <p className="mb-9 ml-1 text-base text-gray-600">
                Ingresa con tu correo
              </p>

              {/* Email */}
              <InputField
                variant="auth"
                extra="mb-3"
                require={true}
                label="Email *"
                placeholder="mail@tuempresa.com"
                id="email"
                type="text"
                value={value}
                onChange={(e) => setValue(e.target.value)}
              />
            </>
          )}

          {/* Checkbox */}
          <div className="mb-4 flex items-center justify-between px-2">
            {!!validate && (
              <a
                className="cursor-pointer text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                onClick={() => setValidate(false)}
              >
                Volver a enviar
              </a>
            )}
          </div>
          <button
            // onClick={(e) => {
            //   e.preventDefault();
            //   if (!!validate) {
            //     validateNude();
            //   } else {
            //     sendNude();
            //   }
            // }}
            className="linear mt-2 w-full rounded-xl bg-brand-500 py-[12px] text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
          >
            {!!validate ? "Validar código" : "Entrar"}
          </button>
        </form>
      </div>
    </div>
  );
}
