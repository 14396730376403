import React, { useState } from "react";

// Decides wich value validate
// validate each value by him self rules
// only return true if all values conditions are satisfied
// keep it simple

const types = {
  text: "TEXT",
  number: "NUMBER",
  email: "EMAIL",
  date: "DATE",
};

const labels = {
  text: "Debe ingresar el campo de texto correctamente",
  number: "Debe ingresar el campo numerico correctamente",
  email: "Debe ingresar el email correctamente",
  date: "Debe ingresar el campo de fecha correctamente",
};

const validateText = (text) =>
  text.replace(/\s+/g, "") !== "" && new RegExp("^[a-zA-Z]+$").test(text);

const validateNumber = (num) => new RegExp("[0-9]+").test(num);

const validateEmail = (email) =>
  new RegExp("[a-zA-Z0-9@.]+").test(email) && email.indexOf("@") !== -1;

const validateDate = (date) =>
  new RegExp("^[0-3]?[0-9]/[0-3]?[0-9]/(?:[0-9]{2})?[0-9]{2}$").test(date);

const validateCustom = (reg, val) => new RegExp(reg).test(val);

const useForm = (initialValues, validateValues = false) => {
  const [values, setValues] = useState(initialValues);
  const [validate, setValidation] = useState({
    state: false,
    message: [],
  });

  if (!!validateValues) {
    let { text, number, date, email } = types;
    let message = Array(),
      res;
    let validForm = validateValues.every((item) => {
      if (!!item.validations && !!item.validations.length) {
        if (
          !item.validations.every((reg) =>
            validateCustom(reg, values[item.key])
          )
        ) {
          return false;
        }
      }

      switch (item.type) {
        case text:
          res = validateText(values[item.key]);
          if (!res) message.push({ key: item.key, message: labels.text });
          return res;
          break;
        case number:
          res = validateNumber(values[item.key]);
          if (!res) message.push({ key: item.key, message: labels.number });
          return res;
          break;
        case date:
          res = validateDate(values[item.key]);
          if (!res) message.push({ key: item.key, message: labels.date });
          return res;
        case email:
          res = validateEmail(values[item.key]);
          if (!res) message.push({ key: item.key, message: labels.email });
          return res;
          break;
      }
    });

    if (!validate.state && validForm) {
      setValidation({ state: validForm, message: [] });
    } else if (
      message.length != validate.message.length ||
      (validate.state && !validForm)
    ) {
      setValidation({ state: validForm, message });
    }
  }

  return [
    values,
    ({ name, value }, clear = null) => {
      if (!!clear) {
        setValues(clear);
        return;
      }
      setValues({
        ...values,
        [name]: value,
      });
    },
    validate,
  ];
};

export { useForm, types };
