import React from "react";
import { Link } from "react-router-dom";

export const NewHeadquarter = () => {
  const handleChange = (e) => {
    const { name, value } = e.target;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log();
  };

  return (
    <div className="flex w-full flex-col gap-5 pt-2">
      <div className="m-4 grid grid-cols-2 gap-1 ">
        <div className="m-2 rounded-lg bg-white p-6 shadow-md">
          <form onSubmit={handleSubmit}>
            <div className="mp-4 mb-4 flex flex-row">
              <h1 className=" mr-5 font-bold text-gray-700">Nombre:</h1>
              <input
                type="text"
                id="name_Headquarter"
                name="name_Headquarter"
                onChange={handleChange}
                className="w-full rounded border bg-gray-100 px-3 py-2 focus:border-blue-500 focus:outline-none"
              />
            </div>

            <div className="mp-5 mb-4 flex flex-row">
              <label
                htmlFor="direccion"
                className="mr-2 block font-bold text-gray-700"
              >
                Dirección:
              </label>
              <input
                type="text"
                id="address_Headquarter"
                name="address_Headquarter"
                onChange={handleChange}
                className=" w-full rounded border bg-gray-100 px-3  py-2 focus:border-blue-500 focus:outline-none"
              />
            </div>

            <div className="container m-2 ml-20 h-44 w-auto items-center rounded-xl bg-gray-200"></div>

            <div className="mp-4 mb-4 flex flex-row">
              <label
                htmlFor="email"
                className="mr-9 block font-bold text-gray-700"
              >
                Email:
              </label>
              <input
                type="email"
                id="email_Headquarter"
                name="email_Headquarter"
                onChange={handleChange}
                className="w-full rounded border bg-gray-100 px-3  py-2  focus:border-blue-500 focus:outline-none"
              />
            </div>

            <div className="mp-4 mb-4 flex flex-row">
              <label
                htmlFor="telefono"
                className="mr-3 block font-bold text-gray-700"
              >
                Teléfono:
              </label>
              <input
                type="tel"
                id="phone_Headquarter"
                name="phone_Headquarter"
                onChange={handleChange}
                className="w-full rounded border bg-gray-100 px-3 py-2  focus:border-blue-500 focus:outline-none"
              />
            </div>

            <div className="mp-4 mb-4 flex flex-row">
              <label
                htmlFor="cantidadPapel"
                className="mb-2 block font-bold text-gray-700"
              >
                Cantidad de Papel:
              </label>
              <input
                type="number"
                id="amount_paper_Headquarter"
                name="amount_paper_Headquarter"
                onChange={handleChange}
                className="w-full rounded border bg-gray-100 px-3  py-2 focus:border-blue-500 focus:outline-none"
              />
            </div>

            <div className="mp-4 mb-4 flex flex-row">
              <label
                htmlFor="numeroEmpleados"
                className="mr-2 block font-bold text-gray-700"
              >
                Número de Usuarios:
              </label>
              <input
                type="number"
                id="quantity_employer_Headquarter"
                name="quantity_employer_Headquarter"
                onChange={handleChange}
                className="bg-neutral-100 w-full rounded border bg-gray-100 px-3  py-2 focus:border-blue-500 focus:outline-none"
              />
            </div>
          </form>
        </div>
        <div className="m-2 rounded-lg bg-white p-6 shadow-md ">
          <div className="flex flex-row">
            <label className="mr-2 block font-bold text-gray-700">
              Impresora:
            </label>
            <select
              type="selet"
              id="print_Headquarter"
              name="print_Headquarter"
              onChange={handleChange}
              className="w-full rounded border bg-gray-100  px-3   py-2 focus:border-blue-500 focus:outline-none"
            >
              <option value="noe">HP200PRINTGO-ZX</option>
            </select>
            <Link to={`/admin/headquarter/printer`}>
              <button className=" ml-2 bg-blue-500 p-2 text-2xl font-bold hover:bg-blue-300">
                +
              </button>
            </Link>
          </div>
          <div className=" m-2 ml-24 h-96 w-auto rounded-xl  bg-gray-100 p-4  ">
            <div className=" relative m-4 mt-1  rounded-2xl bg-white p-3 pt-2 shadow">
              <button className=" absolute right-5 top-3 h-5 w-16 rounded-lg bg-green-500 text-center text-xs font-bold text-white">
                activar
              </button>
              <h1 className="text-neutral-800 w-96 text-lg font-bold leading-7 text-white">
                capital work
              </h1>
              <p className=" text-zinc-500 text-base font-normal leading-relaxed">
                Direccion: Av #36, Bogota, Colombia
              </p>
              <p className=" text-zinc-500 text-base font-normal leading-relaxed">
                Color: ByN, Color
              </p>
              <p className=" text-zinc-500 text-base font-normal leading-relaxed">
                Tipo de hojas: A4, Carta, Oficio, A3
              </p>
            </div>
          </div>

          <div
            className="
        mt-20
         flex justify-end "
          >
            <button
              type="reset"
              className="  focus:shadow-outline mr-4 rounded bg-gray-400 px-4 py-2 font-bold text-white hover:bg-red-700 focus:outline-none"
            >
              Cancelar
            </button>

            <button
              type="submit"
              className=" focus:shadow-outline ml-4 rounded bg-blue-400  px-4 py-2 font-bold text-white hover:bg-blue-500  focus:outline-none"
            >
              Aceptar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
